
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300&display=swap');
body {
}

:root {
	--primary-color: #00ADD2;
	--secondary-color: #FF5757;
  --dark-primary-color: #004AAD;
  font-size: 0.8rem;
}

h1 {
  font-family: 'Anton', sans-serif;
  color:  white;
  /* background: url('./logo_small.png') no-repeat; */
  background-position: left center;
  background-size: 1em 1em;
  font-weight: 100;
  font-size: 2rem;
}

h3 {
  letter-spacing: 0.1em;
  font-family: 'Anton', sans-serif;
  background: url('./logo_small.png') no-repeat;
  color:  var(--dark-primary-color);
  background-position: left center;
  background-size: 1em 1em;
  padding-left: 2em;
  font-weight: 100;
  font-size: 1.6em;
}

h2 {
  font-family: 'Anton', sans-serif;
  margin: auto;
  /* letter-spacing: 0.05em; */
  text-align: left;
  /* font-family: 'Magz', sans-serif; */
  color:  var(--dark-primary-color);
  background-position: left center;
  background-size: 1em 1em;
  padding: 2em 0;
  font-weight: 300;
  font-size: 3rem;
}

.link {
  font-family: 'Anton', sans-serif;
  font-weight: lighter;
}

p b {
  /* font-size: 1.4rem; */
  /* color: var(--secondary-color); */
}

p {
  font-family: "Open Sans", Sans-serif;
  font-weight: 300;
}

.App {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pageContent {
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1000px;
  margin: auto;
}

.pageContent p {
    text-align: left;
	font-size: 1.2rem;
	max-width: 600px;
}

.social a {
	margin: 0 5px;
}

.seeMore {
  font-family: 'Anton', sans-serif;
  font-weight: 100;
}

.about, .about > * {
    z-index: 20;
}

.about p {
  /* margin-bottom: 2em; */
}

.pageContent iframe {
  box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
  margin: 20px auto;
  width: 100%;
  height: 460px;
  max-width: 800px;
}