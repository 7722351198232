
.pageWrapper {
    text-align: left;
    padding-left: 1em;
    padding-right: 1em;
    z-index: 10;
    padding-top: 20vh;
    position: relative;
}


.pageContent {
    max-width: 1000px;
    z-index: 10;
}


.pageContent li {
    background: url('../logo_small.png') no-repeat;
    background-position: left center;
    background-size: 1em 1em;
    padding-left: 2em;
    font-family: "Open Sans", Sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    max-width: 600px;
}

.pageTitle {
    padding-top: 180px;
    z-index: 20;
    position: relative;
}

.page h1 {
    font-size: 4rem;
    background: none;
}

.formGroup {
    line-height: 2rem;
}

.formGroup input, .formGroup textarea {
    border: none;
    margin: 20px auto;
    line-height: 2rem;
}
.formGroup {
    padding: 0.2rem 0.6rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  border: transparent;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0; 
}

.form-control {
    line-height: 2rem;
    padding: 1rem 1rem;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    width: 100%;
}

  .form-control:active, .form-control:focus {
    border: transparent;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: transparent; 
}

.pointList li {
    margin-bottom: 0.5em;
}