@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');


* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Montserrat', 'Open Sans', sans-serif;
	line-height: 1.5;
}

a {
	text-decoration: none;
	color: var(--primary-color);
}

.showcase h3 {
	color: white;
	font-size: 1.2rem;
	background: none;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
}

.showcase {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
	padding: 0 20px;
}

.video-container {
  z-index: -5;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 98vh;
	overflow: hidden;
	background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
}

.logo {
  max-width: 50vw;
}

.video-container video {
	min-width: 100%;
	min-height: 100%;
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	object-fit: cover;
}

.video-container:after {
	content: '';
	z-index: 1;
	height: 98vh;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(41, 43, 70, 0.8);
	position: absolute;
}

.content {
  padding: 2em;
	z-index: 2;
  /* background: rgb(18,8,186);
  background: linear-gradient(30deg, rgba(18,8,186,1) 0%, rgba(9,9,121,0.7035189075630253) 50%, rgba(0,212,255,1) 100%); */
}

.content .logo {
	margin-bottom: 10vh;
}

.btn {
  font-weight: bold;
	display: inline-block;
	padding: 10px 30px;
	background: var(--primary-color);
	color: #fff;
	border-radius: 5px;
	border: solid #fff 1px;
	margin-top: 25px;
	/* opacity: 0.7; */
}

.btn:hover {
	transform: scale(0.98);
}