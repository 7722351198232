
 .video-grid.front-page {
    max-width: 1280px;
    margin: 0 auto;
    padding: 1em 2em;
  }

  .videos {
    max-width: 100%;
  }
  
  ul.video-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  li.video {
    flex-grow: 1;
    /* position: relative; */
    overflow: hidden;
    width: 25%;
    width: -webkit-calc(100% / 4);
    width: calc(100% / 4);
    margin: 10px;
  }
  li.video a {
    outline: none;
  }
  li.video:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.15s;
    z-index: -1;
  }
  li.video figure {
    display: block;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  li.video figure img {
    display: block;
    max-width: 100%;
    height: auto;
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.2s;
  }
  li.video figure figcaption {
    position: absolute;
    top: 0;
    width: 100%;
    background: #004bade5;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 1rem;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.2s;
  }
  li.video:hover figure img {
    opacity: 1;
    transform: scale(1);
  }
  li.video:hover figure figcaption {
    opacity: 0;
    /* transform: translateY(50%); */
  }
  li.video:hover:before {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  @media screen and (max-width: 800px) {
    li.video {
      width: 50%;
      width: -webkit-calc(100% / 2);
      width: calc(100% / 2);
    }
  }
  @media screen and (max-width: 640px) {
    li.video {
      width: 100%;
    }
  }