@charset "UTF-8";
/****
SETUP
****/
@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);
/*
There used to be a menu icon loaded as a (part of a wasteful) font here. It isn't hosted anymore. Such is life.
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-style: normal;
  speak: none;
  font-weight: normal;
  vertical-align: top;
}

.icon-menu:before {
  font-family: 'magz', sans-serif;
  content: "";
}

body {
  font-family: "Quattrocento Sans", Helmet, Freesans, Tahoma, Geneva, sans-serif;
}

button {
  margin: auto;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4);
  height: 5rem;
  border: none;
  -moz-transition: color 100ms ease-in-out, background 100ms ease-in-out;
  -o-transition: color 100ms ease-in-out, background 100ms ease-in-out;
  -webkit-transition: color 100ms ease-in-out, background 100ms ease-in-out;
  transition: color 100ms ease-in-out, background 100ms ease-in-out;
}

.nav {
    width: 100%;
    position: absolute;
    z-index: 999;
  padding: 0;
  font-size: 1em;
  letter-spacing: 0.1em;
  text-align: center;
}
.nav .menu-toggle {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  background-color: white;
  text-decoration: none;
  text-align: center;
  font-size: 2em;
  color: var(--primary-color);
  width: 100%;
}
.menu-item {
  width: 100%;
}
.nav .menu-toggle svg {
  margin: auto;
}
/* .nav .menu-toggle:hover, .nav .menu-toggle:focus {
  color: #eee;
  background-color: var(--primary-color);
} */
.nav .menu-toggle-text {
  font-size: 0.55em;
  display: block;
}
.no-js .nav .menu-toggle {
  display: none;
}
.nav .expanded-menu-toggle {
  color: #eeeeaa;
  background-color: white;
}
.no-fontface .nav .icon-menu:before {
  display: none;
}
.menu .mobileHidden {
  display: none;
}

/* .menu is visually hidden by default */
.menu {
  position: relative;
  z-index: 1;
  /* background-color: white; */
  border: 0;
  margin: -1px auto;
  overflow: hidden;
  padding: 0;
  max-height: 1px;
  min-height: 0;
  clip: rect(0 0 0 0);
  /* -moz-box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2); */
  -moz-transition: max-height 600ms ease-out, min-height 600ms ease-in, padding 600ms ease-in;
  -o-transition: max-height 600ms ease-out, min-height 600ms ease-in, padding 600ms ease-in;
  -webkit-transition: max-height 600ms ease-out, min-height 600ms ease-in, padding 600ms ease-in;
  transition: max-height 600ms ease-out, min-height 600ms ease-in, padding 600ms ease-in;
  /* end .menu li */
}
.menu li a, .link {
  cursor: default;
  display: block;
  padding: 1em 0;
  background-color: transparent;
  text-decoration: none;
  text-align: center;
  font-size: 1.2em;
  /* letter-spacing: 0.3em; */
  color: white;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.menu li a:hover, .menu li a:focus {
  /* color: #eeeeaa;
  background: white; */
}
/* .menu li ul.sub-menu,
.menu li ul.children {
  display: none;
} */

.no-js .menu,
.expanded-mobile-menu {
  -moz-transition: max-height 200ms ease-in, min-height 200ms ease-out, padding 200ms ease-in;
  -o-transition: max-height 200ms ease-in, min-height 200ms ease-out, padding 200ms ease-in;
  -webkit-transition: max-height 200ms ease-in, min-height 200ms ease-out, padding 200ms ease-in;
  transition: max-height 200ms ease-in, min-height 200ms ease-out, padding 200ms ease-in;
  clip: auto;
  min-height: 100vh;
  max-height: 100em;
}

@media only screen and (min-width: 30.1em) {
  .menu li {
    float: left;
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}
@media only screen and (min-width: 48em) {
  .nav .menu-toggle {
    display: none;
  }
  .menu .mobileHidden {
    display: initial;
  }

  .menu,
  .no-js .menu,
  .expanded-mobile-menu {
    margin: 0;
    min-height: 0;
    max-height: 999em;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    overflow: visible;
    /* background: white; */
    transition: box-shadow 0.3s;
    /* -moz-box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2); */
  }

  .menu:hover,
  .no-js .menu:hover,
  .expanded-mobile-menu:hover {
    /* -moz-box-shadow: inset 0 0 0px 3px rgba(0, 0, 0, 0.0), 0 10px 10px rgba(0, 0, 0, 0.0);
    -webkit-box-shadow: inset 0 0 0px 3px rgba(0, 0, 0, 0.0), 0 10px 10px rgba(0, 0, 0, 0.0);
    box-shadow: inset 0 0 0px 3px rgba(0, 0, 0, 0.0), 0 10px 10px rgba(0, 0, 0, 0.0); */
  }
  .menu li,
  .no-js .menu li,
  .expanded-mobile-menu li {
    float: none;
    padding-top: 1em;
    padding-bottom: 1em;
    width: auto;
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
  .lt-ie8 .menu li, .lt-ie8
  .no-js .menu li, .lt-ie8
  .expanded-mobile-menu li {
    display: inline;
    zoom: 1;
  }
  .menu li a,
  .no-js .menu li a,
  .expanded-mobile-menu li a {
    padding: 0.3em 2em;
    color: white;
  }
  .menu li a:hover, .menu li a:focus,
  .no-js .menu li a:hover,
  .no-js .menu li a:focus,
  .expanded-mobile-menu li a:hover,
  .expanded-mobile-menu li a:focus {
    /* color: white;
    background: var(--primary-color); */
  }
  .menu li ul.sub-menu,
  .menu li ul.children,
  .no-js .menu li ul.sub-menu,
  .no-js .menu li ul.children,
  .expanded-mobile-menu li ul.sub-menu,
  .expanded-mobile-menu li ul.children {
    display: block;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    white-space: nowrap;
    -moz-transition: max-height 400ms ease-out, min-height 500ms ease-in, padding 500ms ease-out, opacity 500ms ease-in;
    -o-transition: max-height 400ms ease-out, min-height 500ms ease-in, padding 500ms ease-out, opacity 500ms ease-in;
    -webkit-transition: max-height 400ms ease-out, min-height 500ms ease-in, padding 500ms ease-out, opacity 500ms ease-in;
    transition: max-height 400ms ease-out, min-height 500ms ease-in, padding 500ms ease-out, opacity 500ms ease-in;
    background: white;
    text-align: left;
    -moz-border-radius: 0 4px 4px 4px;
    -webkit-border-radius: 0;
    border-radius: 0 4px 4px 4px;
    opacity: 0;
    border: 0;
    margin: -1px 0;
    overflow: hidden;
    padding: 0;
    max-height: 1px;
    min-height: 0;
  }
  .menu li ul.sub-menu li,
  .menu li ul.children li,
  .no-js .menu li ul.sub-menu li,
  .no-js .menu li ul.children li,
  .expanded-mobile-menu li ul.sub-menu li,
  .expanded-mobile-menu li ul.children li {
    display: block;
    padding: 0.2em 0.4em 0;
  }
  .menu li ul.sub-menu li a,
  .menu li ul.children li a,
  .no-js .menu li ul.sub-menu li a,
  .no-js .menu li ul.children li a,
  .expanded-mobile-menu li ul.sub-menu li a,
  .expanded-mobile-menu li ul.children li a {
    font-size: 1em;
    padding: 0.2em 0.5em 0.2em 0.8em;
    display: block;
    line-height: 1.5;
    margin: 0;
    text-align: left;
    background: transparent;
    color: var(--dark-primary-color);
  }
  .menu li ul.sub-menu li a:hover, .menu li ul.sub-menu li a:focus,
  .menu li ul.children li a:hover,
  .menu li ul.children li a:focus,
  .no-js .menu li ul.sub-menu li a:hover,
  .no-js .menu li ul.sub-menu li a:focus,
  .no-js .menu li ul.children li a:hover,
  .no-js .menu li ul.children li a:focus,
  .expanded-mobile-menu li ul.sub-menu li a:hover,
  .expanded-mobile-menu li ul.sub-menu li a:focus,
  .expanded-mobile-menu li ul.children li a:hover,
  .expanded-mobile-menu li ul.children li a:focus {
    color: var(--primary-color);
  }
  .menu li ul.sub-menu li:last-child a,
  .menu li ul.children li:last-child a,
  .no-js .menu li ul.sub-menu li:last-child a,
  .no-js .menu li ul.children li:last-child a,
  .expanded-mobile-menu li ul.sub-menu li:last-child a,
  .expanded-mobile-menu li ul.children li:last-child a {
    border-bottom: 0;
  }
  .menu li:hover ul, .menu li.focused ul,
  .no-js .menu li:hover ul,
  .no-js .menu li.focused ul,
  .expanded-mobile-menu li:hover ul,
  .expanded-mobile-menu li.focused ul {
    -moz-transition: max-height 800ms ease-in, min-height 500ms ease-out, padding 500ms ease-out, opacity 800ms ease-out;
    -o-transition: max-height 800ms ease-in, min-height 500ms ease-out, padding 500ms ease-out, opacity 800ms ease-out;
    -webkit-transition: max-height 800ms ease-in, min-height 500ms ease-out, padding 500ms ease-out, opacity 800ms ease-out;
    transition: max-height 800ms ease-in, min-height 500ms ease-out, padding 500ms ease-out, opacity 800ms ease-out;
    /* again using min/max heights to deal with unknown length of lists */
    min-height: 8em;
    max-height: 100em;
    padding: 0.4em 0;
    opacity: 1;
  }
  .menu li:hover ul ul, .menu li.focused ul ul,
  .no-js .menu li:hover ul ul,
  .no-js .menu li.focused ul ul,
  .expanded-mobile-menu li:hover ul ul,
  .expanded-mobile-menu li.focused ul ul {
    min-height: 0;
    padding: 0;
  }
  .menu li:hover > a,
  .no-js .menu li:hover > a,
  .expanded-mobile-menu li:hover > a {
    /* background: var(--primary-color); */
    color: var(--primary-color);
  }

  /* end .menu */
}
/* end media query*/
.headerLogo {
  height: 3em;
  margin: 1em auto 0 auto;
}