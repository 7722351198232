

#about {
    text-align: left;
	  padding-top: 20vh;
    z-index: 20;
    position: relative;
}

.imageContainer {
  z-index: 1;
  position: absolute;
  overflow-y: visible;
  left: 0;
  width: 100%;
  /* top: 57vh; */
}

.imageBox {
  margin-top: 3em;
  width: auto;
}

.about .imageBox img {
  box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
}

.imageBox img {
  width: 100%;
}

.imageBox p i {
  font-size: 0.6em;
  line-height: 0.6em;
  color: var(--dark-primary-color);
}

.imageBox p {
  height: auto;
}

.imageContainer img {
  transform: translateY(-40%);
  width: 100%;
}
