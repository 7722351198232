.backgroundRibbon {
    margin-top: 5em;
padding: 0 25px 25px;
position: relative;
width: 100%;
}

.backgroundRibbon::after {
content: '';
filter: sepia(0.5) drop-shadow(2px 4px 6px black);
background-image: url("https://padelhallit.fi/wp-content/uploads/2021/03/mita-padel-on.jpeg");
height: 450px;
background-size: cover;
left: 0;
position: absolute;
top: 0;
width: 100%;
z-index: 1;
}

@media (min-width: 900px) {
.backgroundRibbon {
    padding: 0 0 25px;
}
}

.ribbonContainer {
margin: 0 auto;
padding: 200px 0 0;
max-width: 960px;
width: 100%;
}

.panel {
background-color: #fff;
border-radius: 10px;
padding: 15px 25px;
position: relative;
width: 100%;
z-index: 10;
}

.pricing-table {
/* box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02); */
display: flex;
background-attachment: fixed;
background-size: contain;
background-position: center;
/* background-image: url('../../logo_small.png'); */
flex-direction: column;
}

.pricing-table:before {
    content: ' ';
    display: block;
    position: absolute;
    /* background-attachment: fixed; */
    background-size: initial;
    background-position: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.08;
    background-image: url('../../PadelAucklandalt.svg');
    background-repeat: repeat;
    z-index: 20;
    /* background-image: url('../../logo_small.png');
    background-repeat: no-repeat; */
}

@media (min-width: 900px) {
.pricing-table {
    flex-direction: row;
}
}

.pricing-table * {
text-align: center;
}

.pricing-plan {
border-bottom: 1px solid #e1f1ff;
padding: 25px;
z-index: 30;
background: rgba(255, 255, 255, 0.0);
transition: background 0.3s ease;
}

.pricing-plan:hover {
    background: rgba(255, 255, 255, 1);
}

.pricing-plan:last-child {
border-bottom: none;
}

@media (min-width: 900px) {
.pricing-plan {
    border-bottom: none;
    /* border-right: 1px solid #e1f1ff; */
    flex-basis: 100%;
    padding: 25px 50px;
}

.pricing-plan:last-child {
    border-right: none;
}
}

.pricing-plan p {
    font-weight: 700;
}

.pricing-img {
margin-bottom: 25px;
max-width: 100%;
box-sizing: inherit;
width: 75%;
height: auto;
}

.pricing-header {
    padding: 0;
    font-size: 8em;
font-weight: 600;
color: var(--primary-color);
letter-spacing: 1px;
}

.pricing-features {
font-weight: 600;
letter-spacing: 1px;
margin: 50px 0 25px;
}

.pricing-features-item {
border-top: 1px solid #e1f1ff;
font-size: 1em;
line-height: 1.5;
padding: 15px 0;
}

.pricing-features-item:last-child {
border-bottom: 1px solid #e1f1ff;
}

.pricing-price {
color: var(--primary-color);
display: block;
font-size: 32px;
font-weight: 700;
}

.pricing-button {
border: 1px solid #9dd1ff;
border-radius: 10px;
color: var(--primary-color);
display: inline-block;
margin: 25px 0;
padding: 15px 35px;
text-decoration: none;
transition: all 150ms ease-in-out;
}

.pricing-button:hover,
.pricing-button:focus {
background-color: #e1f1ff;
}

.pricing-button.is-featured {
background-color: #48aaff;
color: #fff;
}

.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
background-color: #269aff;
}