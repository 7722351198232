@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
}

:root {
	--primary-color: #00ADD2;
	--secondary-color: #FF5757;
  --dark-primary-color: #004AAD;
  font-size: 0.8rem;
}

h1 {
  font-family: 'Anton', sans-serif;
  color:  white;
  /* background: url('./logo_small.png') no-repeat; */
  background-position: left center;
  background-size: 1em 1em;
  font-weight: 100;
  font-size: 2rem;
}

h3 {
  letter-spacing: 0.1em;
  font-family: 'Anton', sans-serif;
  background: url(/static/media/logo_small.f82a43d9.png) no-repeat;
  color:  #004AAD;
  color:  var(--dark-primary-color);
  background-position: left center;
  background-size: 1em 1em;
  padding-left: 2em;
  font-weight: 100;
  font-size: 1.6em;
}

h2 {
  font-family: 'Anton', sans-serif;
  margin: auto;
  /* letter-spacing: 0.05em; */
  text-align: left;
  /* font-family: 'Magz', sans-serif; */
  color:  #004AAD;
  color:  var(--dark-primary-color);
  background-position: left center;
  background-size: 1em 1em;
  padding: 2em 0;
  font-weight: 300;
  font-size: 3rem;
}

.link {
  font-family: 'Anton', sans-serif;
  font-weight: lighter;
}

p b {
  /* font-size: 1.4rem; */
  /* color: var(--secondary-color); */
}

p {
  font-family: "Open Sans", Sans-serif;
  font-weight: 300;
}

.App {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pageContent {
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1000px;
  margin: auto;
}

.pageContent p {
    text-align: left;
	font-size: 1.2rem;
	max-width: 600px;
}

.social a {
	margin: 0 5px;
}

.seeMore {
  font-family: 'Anton', sans-serif;
  font-weight: 100;
}

.about, .about > * {
    z-index: 20;
}

.about p {
  /* margin-bottom: 2em; */
}

.pageContent iframe {
  box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
  margin: 20px auto;
  width: 100%;
  height: 460px;
  max-width: 800px;
}
@charset "UTF-8";
/****
SETUP
****/
/*
There used to be a menu icon loaded as a (part of a wasteful) font here. It isn't hosted anymore. Such is life.
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-style: normal;
  speak: none;
  font-weight: normal;
  vertical-align: top;
}

.icon-menu:before {
  font-family: 'magz', sans-serif;
  content: "";
}

body {
  font-family: "Quattrocento Sans", Helmet, Freesans, Tahoma, Geneva, sans-serif;
}

button {
  margin: auto;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4);
  height: 5rem;
  border: none;
  transition: color 100ms ease-in-out, background 100ms ease-in-out;
}

.nav {
    width: 100%;
    position: absolute;
    z-index: 999;
  padding: 0;
  font-size: 1em;
  letter-spacing: 0.1em;
  text-align: center;
}
.nav .menu-toggle {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  background-color: white;
  text-decoration: none;
  text-align: center;
  font-size: 2em;
  color: var(--primary-color);
  width: 100%;
}
.menu-item {
  width: 100%;
}
.nav .menu-toggle svg {
  margin: auto;
}
/* .nav .menu-toggle:hover, .nav .menu-toggle:focus {
  color: #eee;
  background-color: var(--primary-color);
} */
.nav .menu-toggle-text {
  font-size: 0.55em;
  display: block;
}
.no-js .nav .menu-toggle {
  display: none;
}
.nav .expanded-menu-toggle {
  color: #eeeeaa;
  background-color: white;
}
.no-fontface .nav .icon-menu:before {
  display: none;
}
.menu .mobileHidden {
  display: none;
}

/* .menu is visually hidden by default */
.menu {
  position: relative;
  z-index: 1;
  /* background-color: white; */
  border: 0;
  margin: -1px auto;
  overflow: hidden;
  padding: 0;
  max-height: 1px;
  min-height: 0;
  clip: rect(0 0 0 0);
  /* -moz-box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2); */
  transition: max-height 600ms ease-out, min-height 600ms ease-in, padding 600ms ease-in;
  /* end .menu li */
}
.menu li a, .link {
  cursor: default;
  display: block;
  padding: 1em 0;
  background-color: transparent;
  text-decoration: none;
  text-align: center;
  font-size: 1.2em;
  /* letter-spacing: 0.3em; */
  color: white;
  border-radius: 2px;
}
.menu li a:hover, .menu li a:focus {
  /* color: #eeeeaa;
  background: white; */
}
/* .menu li ul.sub-menu,
.menu li ul.children {
  display: none;
} */

.no-js .menu,
.expanded-mobile-menu {
  transition: max-height 200ms ease-in, min-height 200ms ease-out, padding 200ms ease-in;
  clip: auto;
  min-height: 100vh;
  max-height: 100em;
}

@media only screen and (min-width: 30.1em) {
  .menu li {
    float: left;
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}
@media only screen and (min-width: 48em) {
  .nav .menu-toggle {
    display: none;
  }
  .menu .mobileHidden {
    display: inline;
    display: initial;
  }

  .menu,
  .no-js .menu,
  .expanded-mobile-menu {
    margin: 0;
    min-height: 0;
    max-height: 999em;
    border-radius: 0;
    overflow: visible;
    /* background: white; */
    transition: box-shadow 0.3s;
    /* -moz-box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2); */
  }

  .menu:hover,
  .no-js .menu:hover,
  .expanded-mobile-menu:hover {
    /* -moz-box-shadow: inset 0 0 0px 3px rgba(0, 0, 0, 0.0), 0 10px 10px rgba(0, 0, 0, 0.0);
    -webkit-box-shadow: inset 0 0 0px 3px rgba(0, 0, 0, 0.0), 0 10px 10px rgba(0, 0, 0, 0.0);
    box-shadow: inset 0 0 0px 3px rgba(0, 0, 0, 0.0), 0 10px 10px rgba(0, 0, 0, 0.0); */
  }
  .menu li,
  .no-js .menu li,
  .expanded-mobile-menu li {
    float: none;
    padding-top: 1em;
    padding-bottom: 1em;
    width: auto;
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
  .lt-ie8 .menu li, .lt-ie8
  .no-js .menu li, .lt-ie8
  .expanded-mobile-menu li {
    display: inline;
    zoom: 1;
  }
  .menu li a,
  .no-js .menu li a,
  .expanded-mobile-menu li a {
    padding: 0.3em 2em;
    color: white;
  }
  .menu li a:hover, .menu li a:focus,
  .no-js .menu li a:hover,
  .no-js .menu li a:focus,
  .expanded-mobile-menu li a:hover,
  .expanded-mobile-menu li a:focus {
    /* color: white;
    background: var(--primary-color); */
  }
  .menu li ul.sub-menu,
  .menu li ul.children,
  .no-js .menu li ul.sub-menu,
  .no-js .menu li ul.children,
  .expanded-mobile-menu li ul.sub-menu,
  .expanded-mobile-menu li ul.children {
    display: block;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    white-space: nowrap;
    transition: max-height 400ms ease-out, min-height 500ms ease-in, padding 500ms ease-out, opacity 500ms ease-in;
    background: white;
    text-align: left;
    border-radius: 0 4px 4px 4px;
    opacity: 0;
    border: 0;
    margin: -1px 0;
    overflow: hidden;
    padding: 0;
    max-height: 1px;
    min-height: 0;
  }
  .menu li ul.sub-menu li,
  .menu li ul.children li,
  .no-js .menu li ul.sub-menu li,
  .no-js .menu li ul.children li,
  .expanded-mobile-menu li ul.sub-menu li,
  .expanded-mobile-menu li ul.children li {
    display: block;
    padding: 0.2em 0.4em 0;
  }
  .menu li ul.sub-menu li a,
  .menu li ul.children li a,
  .no-js .menu li ul.sub-menu li a,
  .no-js .menu li ul.children li a,
  .expanded-mobile-menu li ul.sub-menu li a,
  .expanded-mobile-menu li ul.children li a {
    font-size: 1em;
    padding: 0.2em 0.5em 0.2em 0.8em;
    display: block;
    line-height: 1.5;
    margin: 0;
    text-align: left;
    background: transparent;
    color: var(--dark-primary-color);
  }
  .menu li ul.sub-menu li a:hover, .menu li ul.sub-menu li a:focus,
  .menu li ul.children li a:hover,
  .menu li ul.children li a:focus,
  .no-js .menu li ul.sub-menu li a:hover,
  .no-js .menu li ul.sub-menu li a:focus,
  .no-js .menu li ul.children li a:hover,
  .no-js .menu li ul.children li a:focus,
  .expanded-mobile-menu li ul.sub-menu li a:hover,
  .expanded-mobile-menu li ul.sub-menu li a:focus,
  .expanded-mobile-menu li ul.children li a:hover,
  .expanded-mobile-menu li ul.children li a:focus {
    color: var(--primary-color);
  }
  .menu li ul.sub-menu li:last-child a,
  .menu li ul.children li:last-child a,
  .no-js .menu li ul.sub-menu li:last-child a,
  .no-js .menu li ul.children li:last-child a,
  .expanded-mobile-menu li ul.sub-menu li:last-child a,
  .expanded-mobile-menu li ul.children li:last-child a {
    border-bottom: 0;
  }
  .menu li:hover ul, .menu li.focused ul,
  .no-js .menu li:hover ul,
  .no-js .menu li.focused ul,
  .expanded-mobile-menu li:hover ul,
  .expanded-mobile-menu li.focused ul {
    transition: max-height 800ms ease-in, min-height 500ms ease-out, padding 500ms ease-out, opacity 800ms ease-out;
    /* again using min/max heights to deal with unknown length of lists */
    min-height: 8em;
    max-height: 100em;
    padding: 0.4em 0;
    opacity: 1;
  }
  .menu li:hover ul ul, .menu li.focused ul ul,
  .no-js .menu li:hover ul ul,
  .no-js .menu li.focused ul ul,
  .expanded-mobile-menu li:hover ul ul,
  .expanded-mobile-menu li.focused ul ul {
    min-height: 0;
    padding: 0;
  }
  .menu li:hover > a,
  .no-js .menu li:hover > a,
  .expanded-mobile-menu li:hover > a {
    /* background: var(--primary-color); */
    color: var(--primary-color);
  }

  /* end .menu */
}
/* end media query*/
.headerLogo {
  height: 3em;
  margin: 1em auto 0 auto;
}
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Montserrat', 'Open Sans', sans-serif;
	line-height: 1.5;
}

a {
	text-decoration: none;
	color: var(--primary-color);
}

.showcase h3 {
	color: white;
	font-size: 1.2rem;
	background: none;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
}

.showcase {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
	padding: 0 20px;
}

.video-container {
  z-index: -5;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 98vh;
	overflow: hidden;
	background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
}

.logo {
  max-width: 50vw;
}

.video-container video {
	min-width: 100%;
	min-height: 100%;
  position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	object-fit: cover;
}

.video-container:after {
	content: '';
	z-index: 1;
	height: 98vh;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(41, 43, 70, 0.8);
	position: absolute;
}

.content {
  padding: 2em;
	z-index: 2;
  /* background: rgb(18,8,186);
  background: linear-gradient(30deg, rgba(18,8,186,1) 0%, rgba(9,9,121,0.7035189075630253) 50%, rgba(0,212,255,1) 100%); */
}

.content .logo {
	margin-bottom: 10vh;
}

.btn {
  font-weight: bold;
	display: inline-block;
	padding: 10px 30px;
	background: var(--primary-color);
	color: #fff;
	border-radius: 5px;
	border: solid #fff 1px;
	margin-top: 25px;
	/* opacity: 0.7; */
}

.btn:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}
@-webkit-keyframes autoplay {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    85% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes autoplay {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    85% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes trans {
    0% {
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.3);
              transform: scale(1.3);
    }
  }
  @keyframes trans {
    0% {
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.3);
              transform: scale(1.3);
    }
  }

  body {
    font-weight: 300;
    font-family: "Montserrat", "Open Sans", sans-serif;
    text-align: center;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .auto {
    margin: 0 auto;
    position: relative;
  }
  .auto li {
    position: relative;
    overflow: hidden;
    opacity: 0;
    -webkit-animation: autoplay 60s linear infinite;
            animation: autoplay 60s linear infinite;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.1s 0.2s;
  }
  .auto li:nth-child(1) {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  .auto li:nth-child(2) {
    -webkit-animation-delay: -48s;
            animation-delay: -48s;
  }
  .auto li:nth-child(3) {
    -webkit-animation-delay: -36s;
            animation-delay: -36s;
  }
  .auto li:nth-child(4) {
    -webkit-animation-delay: -24s;
            animation-delay: -24s;
  }
  .auto li:nth-child(5) {
    -webkit-animation-delay: -12s;
            animation-delay: -12s;
  }
  .auto li:nth-child(1), .auto li:nth-child(2), .auto li:nth-child(3), .auto li:nth-child(4), .auto li:nth-child(5) {
    z-index: 1;
    opacity: 1;
    transition-delay: 0s;
  }
  .auto li:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
  }
  .auto li img {
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    -webkit-animation: trans 60s linear infinite;
            animation: trans 60s linear infinite;
  }
footer {
    padding-top: 24px;
    background-color: var(--dark-primary-color);
}


footer a {
    color:  var(--primary-color);
    font-size: 2rem;
    font-weight: bold;
}
  
footer a svg {
    font-size: 4rem;
}
  
footer h3 {
    background: none;
}

footer .container {
    max-width: 1000px;
    text-align: left;
    margin: auto;
    padding-left: 1em;
}

footer h1 {
    color:  var(--primary-color);
    font-size: 2rem !important;
}

footer {
    margin-top: auto;
    text-align: center;
    display: flow-root;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    padding: 2vh 5vw;
}

.footer-clean {
    box-shadow: inset 0px 10px 40px rgba(0, 0, 0, 0.3);
    padding:50px 10px;
    color: #ffffff;
    width: 100%;
    text-align: left;
}
  
.footer-clean h3 {
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:16px;
    padding-left: 0;
    color: var(--primary-color);
    font-weight: 100;
    letter-spacing: 0.2em;
}
  
.footer-clean ul {
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:14px;
    margin-bottom:0;
}

.footer-clean .social a {
    color: #36ff4e;
}

.footer-clean .social img {
    height: 2em;
}
  
.footer-clean ul a {
    text-decoration:none;
    opacity:0.8;
    color: inherit;
    font-size: 1em;
    font-weight: 400;
}
  
.footer-clean ul a:hover {
    opacity:1;
}
  
.footer-clean .item.social {
    text-align:right;
}

.copyright a {
    font-size: 1em;
}
  
@media (max-width:767px) {
    .footer-clean .item {
      text-align:center;
      padding-bottom:20px;
    }
}
  
@media (max-width: 768px) {
    .footer-clean .item.social {
      text-align:center;
    }
}
  
.footer-clean .item.social > a {
    font-size:24px;
    width:40px;
    height:40px;
    line-height:40px;
    display:inline-block;
    text-align:center;
    border-radius:5px;
    border:1px solid #ccc;
    margin-left:10px;
    margin-top:22px;
    opacity:0.75;
}
  
.footer-clean .item.social > a:hover {
    opacity:0.9;
}
  
@media (max-width:991px) {
    .footer-clean .item.social > a {
      margin-top:40px;
    }
}
  
.footer-clean .copyright {
    margin-top:14px;
    margin-bottom:0;
    font-size:13px;
    opacity:0.6;
}

.footer-clean .row {
    margin: auto;
    display: grid;
    grid-template-areas:    "Contact Services About Logos";
    grid-gap: 20px;
    gap: 20px;
    max-width: 1000px;
}
  
@media (max-width:767px) {
    .footer-clean .item.social > a {
      margin-top:10px;
    }

    .footer-clean .row {
        grid-template-areas:    "Contact About"
                                "Services Logo";
    }
}

.mapouter{
    position:relative;
    text-align:left;
    height:300px;
    width:400px;
}
.gmap_canvas {
    overflow:hidden;
    background:none !important;
    height:300px;
    width:400px;
}


#about {
    text-align: left;
	  padding-top: 20vh;
    z-index: 20;
    position: relative;
}

.imageContainer {
  z-index: 1;
  position: absolute;
  overflow-y: visible;
  left: 0;
  width: 100%;
  /* top: 57vh; */
}

.imageBox {
  margin-top: 3em;
  width: auto;
}

.about .imageBox img {
  box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
}

.imageBox img {
  width: 100%;
}

.imageBox p i {
  font-size: 0.6em;
  line-height: 0.6em;
  color: var(--dark-primary-color);
}

.imageBox p {
  height: auto;
}

.imageContainer img {
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  width: 100%;
}

.backgroundRibbon {
    margin-top: 5em;
padding: 0 25px 25px;
position: relative;
width: 100%;
}

.backgroundRibbon::after {
content: '';
-webkit-filter: sepia(0.5) drop-shadow(2px 4px 6px black);
        filter: sepia(0.5) drop-shadow(2px 4px 6px black);
background-image: url("https://padelhallit.fi/wp-content/uploads/2021/03/mita-padel-on.jpeg");
height: 450px;
background-size: cover;
left: 0;
position: absolute;
top: 0;
width: 100%;
z-index: 1;
}

@media (min-width: 900px) {
.backgroundRibbon {
    padding: 0 0 25px;
}
}

.ribbonContainer {
margin: 0 auto;
padding: 200px 0 0;
max-width: 960px;
width: 100%;
}

.panel {
background-color: #fff;
border-radius: 10px;
padding: 15px 25px;
position: relative;
width: 100%;
z-index: 10;
}

.pricing-table {
/* box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02); */
display: flex;
background-attachment: fixed;
background-size: contain;
background-position: center;
/* background-image: url('../../logo_small.png'); */
flex-direction: column;
}

.pricing-table:before {
    content: ' ';
    display: block;
    position: absolute;
    /* background-attachment: fixed; */
    background-size: auto auto;
    background-size: initial;
    background-position: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.08;
    background-image: url(/static/media/PadelAucklandalt.b2203eb0.svg);
    background-repeat: repeat;
    z-index: 20;
    /* background-image: url('../../logo_small.png');
    background-repeat: no-repeat; */
}

@media (min-width: 900px) {
.pricing-table {
    flex-direction: row;
}
}

.pricing-table * {
text-align: center;
}

.pricing-plan {
border-bottom: 1px solid #e1f1ff;
padding: 25px;
z-index: 30;
background: rgba(255, 255, 255, 0.0);
transition: background 0.3s ease;
}

.pricing-plan:hover {
    background: rgba(255, 255, 255, 1);
}

.pricing-plan:last-child {
border-bottom: none;
}

@media (min-width: 900px) {
.pricing-plan {
    border-bottom: none;
    /* border-right: 1px solid #e1f1ff; */
    flex-basis: 100%;
    padding: 25px 50px;
}

.pricing-plan:last-child {
    border-right: none;
}
}

.pricing-plan p {
    font-weight: 700;
}

.pricing-img {
margin-bottom: 25px;
max-width: 100%;
box-sizing: inherit;
width: 75%;
height: auto;
}

.pricing-header {
    padding: 0;
    font-size: 8em;
font-weight: 600;
color: var(--primary-color);
letter-spacing: 1px;
}

.pricing-features {
font-weight: 600;
letter-spacing: 1px;
margin: 50px 0 25px;
}

.pricing-features-item {
border-top: 1px solid #e1f1ff;
font-size: 1em;
line-height: 1.5;
padding: 15px 0;
}

.pricing-features-item:last-child {
border-bottom: 1px solid #e1f1ff;
}

.pricing-price {
color: var(--primary-color);
display: block;
font-size: 32px;
font-weight: 700;
}

.pricing-button {
border: 1px solid #9dd1ff;
border-radius: 10px;
color: var(--primary-color);
display: inline-block;
margin: 25px 0;
padding: 15px 35px;
text-decoration: none;
transition: all 150ms ease-in-out;
}

.pricing-button:hover,
.pricing-button:focus {
background-color: #e1f1ff;
}

.pricing-button.is-featured {
background-color: #48aaff;
color: #fff;
}

.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
background-color: #269aff;
}

 .video-grid.front-page {
    max-width: 1280px;
    margin: 0 auto;
    padding: 1em 2em;
  }

  .videos {
    max-width: 100%;
  }
  
  ul.video-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  li.video {
    flex-grow: 1;
    /* position: relative; */
    overflow: hidden;
    width: 25%;
    width: calc(100% / 4);
    margin: 10px;
  }
  li.video a {
    outline: none;
  }
  li.video:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.15s;
    z-index: -1;
  }
  li.video figure {
    display: block;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  li.video figure img {
    display: block;
    max-width: 100%;
    height: auto;
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    transition: all 0.2s;
  }
  li.video figure figcaption {
    position: absolute;
    top: 0;
    width: 100%;
    background: #004bade5;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 1rem;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    transition: all 0.2s;
  }
  li.video:hover figure img {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  li.video:hover figure figcaption {
    opacity: 0;
    /* transform: translateY(50%); */
  }
  li.video:hover:before {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  @media screen and (max-width: 800px) {
    li.video {
      width: 50%;
      width: calc(100% / 2);
    }
  }
  @media screen and (max-width: 640px) {
    li.video {
      width: 100%;
    }
  }
.banner {
    position: absolute;
    left: 0;
    z-index: 0;
    margin-top: 3em;
}

.pageWrapper {
    text-align: left;
    padding-left: 1em;
    padding-right: 1em;
    z-index: 10;
    padding-top: 20vh;
    position: relative;
}


.pageContent {
    max-width: 1000px;
    z-index: 10;
}


.pageContent li {
    background: url(/static/media/logo_small.f82a43d9.png) no-repeat;
    background-position: left center;
    background-size: 1em 1em;
    padding-left: 2em;
    font-family: "Open Sans", Sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    max-width: 600px;
}

.pageTitle {
    padding-top: 180px;
    z-index: 20;
    position: relative;
}

.page h1 {
    font-size: 4rem;
    background: none;
}

.formGroup {
    line-height: 2rem;
}

.formGroup input, .formGroup textarea {
    border: none;
    margin: 20px auto;
    line-height: 2rem;
}
.formGroup {
    padding: 0.2rem 0.6rem;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  background: transparent;
  border: transparent;
  font-size: 16px;
  font-weight: 400;
  transition: 0.3s;
  border-radius: 0; 
}

.form-control {
    line-height: 2rem;
    padding: 1rem 1rem;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    width: 100%;
}

  .form-control:active, .form-control:focus {
    border: transparent;
    outline: none;
    box-shadow: none;
    border-color: transparent; 
}

.pointList li {
    margin-bottom: 0.5em;
}
